<template>
  <div>
    <img
      :src=" require('@/assets/nhyh/banner.gif')"
      class="banner"
      @click="download"
    >
    <div class="line"></div>
  <div class="container" style="font-size:0; ">
    <img :src="require('@/assets/nhyh/one.gif')"  >
    <img :src="require('@/assets/nhyh/two.gif')" >
    <img :src="require('@/assets/nhyh/three.gif')"  >
  </div>

    
    

  </div>
</template>

<script>
import { fetchList } from "@/api/notice"
import { Base64 } from 'js-base64'
export default {
  data () {
    return {
      active: 2,
      data: {
        list: []
      },
      filter: {
        game_id: '5c3addddf70f45f0a5c6099a3d399742'
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    download () {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      let redirect_url = ''
      if (isiOS) {
        redirect_url = Base64.encodeURL(this.data.download.ios_download)
        // redirect_url = Base64.encodeURL('https://zaxhiyw.cn:7539/IJuZ.html')
      } else {
        redirect_url = Base64.encodeURL(this.data.download.android_download)
        // redirect_url = Base64.encodeURL('https://zaxhiyw.cn:7539/IJuZ.html')
      }
      console.log(redirect_url)

      this.$router.push({
        path: '/redirect',
        query: {
          url: redirect_url
        }
      })
    },
    getList () {
      fetchList(this.filter).then(response => {
        this.data.list = response.list
        this.data.download = response.download
      }).catch(() => {
      })
    }
  }
}
</script>

<style>
html,body{
  overflow-x: hidden;
}
img{
  display: inline-block;
  height: auto;
  max-width: 100%;
}
.line{
  height: 0.63rem;
}
.banner {
  max-width: 100%;
  display: block;
  position: fixed;
  z-index: 1000;
  font-size:0
}
.van-tabs__nav--card {
  margin: 0 !important;
}
.van-cell__title {
  min-width: 70%;
}
</style>